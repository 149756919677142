import { format, differenceInMinutes, parseISO } from 'date-fns';

export const formatIsoToDate = (date: string) => format(date, 'dd/MM/yyyy');

export const formatIsoToDateTime = (date: string) => format(date, 'dd/MM/yyyy HH:mm:ss');

export const getTimeFromIsoDate = (date: string) => format(date, 'HH:mm');


export const calcularDiferencaEmMinutos = (dataInicial: string, dataFinal: string) => {
  const dataInicialFormatada: Date = parseISO(dataInicial);
  const dataFinalFormatada: Date = parseISO(dataFinal);

  return differenceInMinutes(dataFinalFormatada, dataInicialFormatada);
};

export const formatarNumeroTelefone = (numero: string): string => {
  if (numero === "Não informado") return numero;

  let numerosApenas = numero.replace(/\D/g, '');

  if (numerosApenas.startsWith('55')) 
    numerosApenas = numerosApenas.slice(2);
  
  const numeroFormatado = numerosApenas.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})$/, '($1) $2 $3-$4');

  return numeroFormatado;
};

export const formatarCPF = (numero: string): string => {
  const numerosApenas = numero.replace(/\D/g, '');
  const numerosSubstituidos = `${numerosApenas.slice(0, 3)}.***.***-**`;

  return numerosSubstituidos;
};